import React from 'react';
import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';

const Button = styled.button`
  border: 0;
  display: inline-block;
  padding: 13px 16px;
  text-align: center;
  vertical-align: middle;
  background-color: #ca1905;
  color: #ffffff;
  min-width: 120px;
  min-height: 48px;

  &:hover,
  &:focus {
    background-color: #a91504;
    color: #ffffff;
    text-decoration: none;
  }

  &:active {
    background-color: #d54030;
    color: #ffffff;
  }

  &:disabled {
    background-color: #f1f1f1;
    color: #776e64;
    cursor: not-allowed;
  }

  svg {
    color: #ffffff;
  }

  ${props =>
    props.dropdown
      ? `
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      padding-right: 0;
  `
      : ''}
`;

const DownArrow = styled(FiChevronDown)`
  width: 20px;
  height: 20px;
  margin: 0 6px;
`;

export default ({ dropdown = false, onClick, children, ...props }) => (
  <Button dropdown={dropdown} onClick={onClick} {...props}>
    <div>
      <span>{children}</span>
      {dropdown && <DownArrow />}
    </div>
  </Button>
);
