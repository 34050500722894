import { createGlobalStyle } from 'styled-components';
import '../../fonts/fonts.css';
import '../../styles/style.scss';
import device from '../Common/Device';
import scale from '../../utils/scale';

const GlobalStyles = createGlobalStyle`
  body {
    h1 {
      font-family: 'Agenda Semibold', sans-serif;
      font-size: ${scale.desktop(6)}rem;
      line-height: 1.2;

      @media ${device.max.md} {
        font-size: ${scale.mobile(6)}rem;
      }
    }

    h2 {
      font-family: 'Agenda Medium', sans-serif;
      font-size: ${scale.desktop(5)}rem;
      line-height: 1.2;

      @media ${device.max.md} {
        font-size: ${scale.mobile(5)}rem;
      }
    }

    h3 {
      font-family: 'Agenda Medium', sans-serif;
      font-size: ${scale.desktop(4)}rem;
      line-height: 1.2;

      @media ${device.max.md} {
        font-size: ${scale.mobile(4)}rem;
      }
    }

    h4 {
      font-family: 'Agenda Medium', sans-serif;
      font-size: ${scale.desktop(3)}rem;
      line-height: 1.2;

      @media ${device.max.md} {
        font-size: ${scale.mobile(3)}rem;
      }
    }

    h5 {
      font-family: 'Agenda Medium', sans-serif;
      font-size: ${scale.desktop(2)}rem;
      line-height: 1.2;

      @media ${device.max.md} {
        font-size: ${scale.mobile(2)}rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      margin: 0 0 24px 0;

      small {
        font-size: 0.75rem;
      }
    }

    a, a:hover, a:not([href]), a:not([href]):hover {
      color: #007b86;
    }

    ul {
      margin-bottom: 24px;
      padding-left: 1.2rem;
    }

    ul li {
      margin: 0 0 16px 0;
    }

    ul li p {
      margin: 0;
      padding: 0;
    }

    label {
      display: block;
    }

    hr {
      margin: 16px 0;
      border-top: 1px solid #E5E5E5;
    }

    div.modal-content {
      border-radius: 0;
      border: none;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    }
  }

  div.container-lg {
    padding: 0 36px;
  }

  @media ${device.max.sm} {
    div.container-lg {
      padding: 0 24px;
    }
  }
`;

export default GlobalStyles;
