import React, { useState, useRef, useEffect } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Tooltip } from 'reactstrap';

export default ({
  children,
  to,
  activeClassName,
  partiallyActive,
  href,
  isFade = false,
  ...other
}) => {
  const linkRef = useRef();
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, []);

  // if we have a href just render a normal link
  if (href) {
    return (
      <a {...other} href={href}>
        {children}
      </a>
    );
  }

  // if we don't have any link, render a dummy link with a tooltip
  // Fade prop sets tooltip popover effect
  // The popover doesn't trigger "onMouseLeave" event on parent component
  // In-order to trigger event on the parent component the fade prop must be set as ‘true’
  // For all the other scenarios the fade must set as ‘false’

  // eslint-disable-next-line no-script-url
  if (!to || to === '' || to === '#' || to === 'javascript:;') {
    return (
      <>
        <a
          {...other}
          onClick={event => event.preventDefault()}
          ref={linkRef}
          href="#">
          {children}
          {ready && (
            <Tooltip
              placement="bottom"
              isOpen={open}
              target={linkRef.current}
              toggle={() => setOpen(!open)}
              trigger="hover"
              fade={isFade}>
              This link is not active yet
            </Tooltip>
          )}
        </a>
      </>
    );
  }

  // if it's an internal link, render a gatsby link
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <GatsbyLink
        {...other}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}>
        {children}
      </GatsbyLink>
    );
  }

  // otherwise render a regular link
  return (
    <a {...other} href={to}>
      {children}
    </a>
  );
};
