const widths = {
  xs: 0,
  sm: 500,
  md: 768,
  lg: 992
};

const min = {
  sm: `(min-width: ${widths.sm}px)`,
  md: `(min-width: ${widths.md}px)`,
  lg: `(min-width: ${widths.lg}px)`
};

const max = {
  xs: `(max-width: ${widths.sm - 0.02}px)`,
  sm: `(max-width: ${widths.md - 0.02}px)`,
  md: `(max-width: ${widths.lg - 0.02}px)`
};

const only = {
  xs: max.xs,
  sm: `${min.sm} and ${max.sm}`,
  md: `${min.md} and ${max.md}`,
  lg: min.lg
};

export default {
  min,
  max,
  only
};
